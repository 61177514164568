import React from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import StEs from './HospitalLogos/StEs'
import Southcoast from './HospitalLogos/Southcoast'
import BIDMC from './HospitalLogos/BIDMC'
import NEBH from './HospitalLogos/NEBH'
import Faulkner from './HospitalLogos/Faulkner'
import Brigham from './HospitalLogos/Brigham'
import Harvard from './HospitalLogos/Harvard'
import Lowell from './HospitalLogos/Lowell'
import SouthCounty from './HospitalLogos/SouthCounty'
import Carousel from 'nuka-carousel'
import useWindowSize from '../../lib/hooks/useWindowSize'
import SymbolButton from '../shared/SymbolButton'

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${colors.white};
  color: ${colors.white};
  padding: 50px 0px 0px;
  border-bottom: 1px solid ${colors.greylight};
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  @media (max-width: 1280px) {
    width: 90%;
  }
`

const TextHeader = styled.div`
  flex: 1 1 100px;
  display: flex;
  align-items: center;
  color: ${colors.greydark};
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: 4.8px;
  @media (max-width: 1280px) {
    flex: 1 1 80px;
    font-size: 14px;
    text-align: center;
    line-height: 25px;
  }
`
const slidesToShow = (width) => {
  if (width > 1280) return 5
  else if (width > 1100) return 4
  else if (width > 800) return 3
  else if (width > 600) return 2
  return 1
}

const rightArrow = <span className="lnr lnr-arrow-right"></span>
const leftArrow = <span className="lnr lnr-arrow-left"></span>

const controlStyles = { height: '50px', width: '50px' }
const carouselProps = {
  wrapAround: true,
  autoGenerateStyleTag: false,
  initialSlideHeight: 200,
  framePadding: '40px 75px',
  style: {},
  renderTopCenterControls: null,
  renderCenterRightControls: ({ nextSlide }) => (
    <SymbolButton
      symbol={rightArrow}
      onClick={nextSlide}
      styles={controlStyles}
    />
  ),
  renderCenterLeftControls: ({ previousSlide }) => (
    <SymbolButton
      symbol={leftArrow}
      onClick={previousSlide}
      styles={controlStyles}
    />
  ),
  renderBottomCenterControls: null,
}

const Hospitals = () => {
  const size = useWindowSize()

  return (
    <Section id="Hospitals">
      <SectionContent>
        <TextHeader>Trusted by the world's best Hospitals</TextHeader>
        <Carousel {...carouselProps} slidesToShow={slidesToShow(size.width)}>
          <Brigham />
          <Harvard />
          <NEBH />
          <BIDMC />
          <Faulkner />
          <StEs />
          <Southcoast />
          {/* <Berkshire /> */}
          <Lowell />
          <SouthCounty />
        </Carousel>
      </SectionContent>
    </Section>
  )
}

export default Hospitals
