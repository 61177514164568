import React from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import { Media } from '../Media'

/* For some reason styled-components seems to delay in integrating w/ BackgroundImage
   Resulting in some janky behavior on initial loado
   So we use inline styles here instead
  */
const styles = {
  mobile: {
    display: 'flex',
    justifyContent: 'space-around',
    backgroundColor: colors.blue,
    backgroundPosition: 'bottom right',
    backgroundSize: 'contain',
  },

  desktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    color: colors.white,
    position: 'relative',
    backgroundColor: colors.blue,
    width: '100%',
    zIndex: 0,
  },
}

const DesktopGreySection = styled.div`
  position: absolute;
  width: 25%;
  height: 100%;
  right: 0px;
  background-color: ${colors.greylight};
  z-index: 2;
`

const DesktopSection = ({ children }) => {
  return (
    <div id="header" style={styles.desktop}>
      {children}
      <DesktopGreySection />
    </div>
  )
}

const MobileSection = ({ children }) => {
  return (
    <div id="header" style={styles.mobile}>
      {children}
    </div>
  )
}

const Section = ({ children }) => (
  <>
    <Media at="sm">
      <MobileSection>{children}</MobileSection>
    </Media>
    <Media greaterThanOrEqual="lg">
      <DesktopSection>{children}</DesktopSection>
    </Media>
  </>
)

export default Section
