import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Img from 'gatsby-image'
import { motion } from 'framer-motion'

const Image = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "nurse/sync.png" }) {
        childImageSharp {
          fluid(maxWidth: 375, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <motion.div
        className={className}
        animate={{ rotate: 360 }}
        transition={{
          loop: Infinity,
          ease: 'linear',
          duration: 30,
        }}
      >
        <Img
          fluid={data.placeholderImage.childImageSharp.fluid}
          style={{ width: '100%' }}
        />
      </motion.div>
    </>
  )
}

const StyledImage = styled(Image)`
  width: 480px;
  position: absolute;
  bottom: 115px;
  right: 25%;
  margin-right: -305px;
  z-index: 3;
  @media (max-width: 1024px) {
    width: 310px;
    left: 50%;
    margin-left: -135px;
    bottom: -32px;
    z-index: 2;
  }
`

export default StyledImage
