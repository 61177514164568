import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "icons/icon_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 39) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const Wrapper = styled.div`
  flex: 0 0 39px;
  @media (max-width: 1280px) {
    width: 39px;
  }
`

const C = () => (
  <Wrapper>
    <Image />
  </Wrapper>
)

export default C
