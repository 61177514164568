import React from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import TextMessageIcon from './TextMessageIcon'
import { Media } from '../Media'

const TextMessage = styled.div`
  height: 100px;
  margin: 5px 0px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  background-color: ${colors.white};
  @media (max-width: 1024px) {
    height: 50px;
    margin: 3px 0px;
    padding: 0px 5px;
  }
`

const TextMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
  @media (max-width: 1024px) {
    padding: 5px;
  }
`

const TextMessageHeader = styled.div`
  color: ${colors.greydark};
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
  @media (max-width: 1024px) {
    font-size: 8px;
  }
`

const TextMessageDetails = styled.div`
  color: ${colors.greydark};
  font-size: 10px;
  font-weight: 400;
  @media (max-width: 1024px) {
    font-size: 8px;
  }
`

const TextMessageHospital = styled.div`
  color: ${colors.red};
  font-size: 12px;
  font-weight: 700;
  line-height: 28px;
  text-transform: uppercase;
  @media (max-width: 1024px) {
    font-size: 8px;
    line-height: 12px;
  }
`

const C = ({ id, index, event, date, hospital }) => (
  <TextMessage key={id} index={index}>
    <TextMessageIcon event={event} />
    <TextMessageContent>
      <TextMessageHeader>{event.name}</TextMessageHeader>
      <TextMessageHospital>{hospital}</TextMessageHospital>
      <Media greaterThanOrEqual="lg">
        <TextMessageDetails>
          {event.message} {date.toLocaleDateString('en-US')}
        </TextMessageDetails>
      </Media>
    </TextMessageContent>
  </TextMessage>
)

export default C
