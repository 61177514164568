import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import colors from '../../lib/colors'
import { Media } from '../Media'

import BackgroundImage from 'gatsby-background-image'

const sectionStyles = {
  display: 'flex',
  color: colors.white,
  flexDirection: 'row',
  justifyContent: 'space-around',
  backgroundColor: colors.blue,
  padding: '75px 0',
}

const desktopStyles = {
  width: '100%',
  backgroundSize: '145px, 325px, 145px',
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat, no-repeat, no-repeat',
  backgroundPosition: 'right 10px top 10px, center, left 10px bottom 10px',
}

const DesktopSection = ({ children, className }) => {
  const { center, leftBottom, rightTop } = useStaticQuery(
    graphql`
      query {
        center: file(relativePath: { eq: "icons/icon_faded.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 279) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        leftBottom: file(relativePath: { eq: "grid_white.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 145) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        rightTop: file(relativePath: { eq: "grid_white.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 145) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `,
  )

  const backgroundFluidImageStack = [
    leftBottom.childImageSharp.fluid,
    center.childImageSharp.fluid,
    rightTop.childImageSharp.fluid,
  ].reverse()

  return (
    <BackgroundImage
      Tag="section"
      id="contact"
      className={className}
      style={{ ...desktopStyles, ...sectionStyles }}
      fluid={backgroundFluidImageStack}
    >
      {children}
    </BackgroundImage>
  )
}

const MobileSection = ({ children }) => {
  return <div style={sectionStyles}>{children}</div>
}

const Section = ({ children }) => (
  <>
    <Media at="sm">
      <MobileSection>{children}</MobileSection>
    </Media>
    <Media greaterThanOrEqual="lg">
      <DesktopSection>{children}</DesktopSection>
    </Media>
  </>
)

export default Section
