import colors from '../../lib/colors'
import styled from 'styled-components'

const HeaderLink = styled.div`
  color: ${colors.white};
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  padding: 5px;
  margin: 10px;
  color: ${colors.white};
  @media (max-width: 1440px) {
    font-size: 15px;
  }
  @media (max-width: 1280px) {
    font-size: 12px;
  }
`

export default HeaderLink
