import React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import Illustration from './Illustration'
import { motion } from 'framer-motion'
import { useInView } from 'react-intersection-observer'
import Icon from './Icon'
import Button from '../shared/Button'
import StyledLink from '../shared/StyledLink'

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${colors.greylight};
  color: ${colors.white};
  padding: 50px 0px;
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  @media (max-width: 1280px) {
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }
`

const ContentColumn = styled.div`
  display: flex;
  flex: 0 0 600px;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1280px) {
    flex: 0 1 100%;
  }
`

const Content = styled.div`
  flex: 0 0 400px;
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Header = styled.div`
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
`

const HeaderText = styled.div`
  padding-left: 20px;
  color: ${colors.blue};
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: 4.8px;
`
const HeaderHRWrapper = styled.div`
  flex: 0 0 250px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1280px) {
    display: none;
  }
`

const HeaderHR = styled.div`
  flex: 0 0 2px;
  background-color: ${colors.greyruler};
`

const HeroText = styled.div`
  padding: 10px 0px;
  color: #2a3449;
  font-size: 42px;
  font-weight: 700;
  line-height: 55px;
  @media (max-width: 1280px) {
    width: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 36px;
  }
`

const Details = styled.div`
  color: ${colors.greydark};
  padding: 20px 0px;
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: 1280px) {
    flex: 0 1 100%;
    text-align: center;
  }
`

const IllustrationMotionDiv = styled(motion.div)`
  flex: 0 0 640px;
  padding: 60px;
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    flex: 1 1 auto;
  }
`

const RepManagement = () => {
  const [shown, setShown] = useState(false)
  const [ref, inView] = useInView({ threshold: 0.33 })
  useEffect(() => setShown(shown || inView), [inView])
  useEffect(() => window.scrollTo(0, 0), [])
  return (
    <Section id="rep-management" ref={ref}>
      <SectionContent>
        <IllustrationMotionDiv
          animate={shown ? 'visible' : 'hidden'}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
          transition={{ duration: 2, delay: 0 }}
        >
          <Illustration />
        </IllustrationMotionDiv>
        <ContentColumn>
          <motion.div
            animate={shown ? 'visible' : 'hidden'}
            variants={{
              visible: { opacity: 1, y: 0 },
              hidden: { opacity: 0, y: 100 },
            }}
            transition={{ duration: 1.5, delay: 0 }}
          >
            <Content>
              <Header>
                <Icon />
                <HeaderText>Rep Management</HeaderText>
                <HeaderHRWrapper>
                  <HeaderHR />
                </HeaderHRWrapper>
              </Header>
              <HeroText>Say goodbye to hours spent coordinating reps.</HeroText>
              <Details>
                Torq is a HIPAA-compliant, EHR-integrated platform that saves
                hospitals both time and resources by enabling automated, secure
                information sharing with vendors in real-time—no phone calls,
                texts, or emails necessary. This way, vendors are always in sync
                with each hospital’s needs, and, through the platform’s
                acknowledgment feature, vendors can confirm once they’ve
                received case updates. Now your team can be confident it has the
                proper equipment and coverage for every surgery, every time, all
                the time.
              </Details>
              <StyledLink href="#contact">
                <Button text="Contact Us" />
              </StyledLink>
            </Content>
          </motion.div>
        </ContentColumn>
      </SectionContent>
    </Section>
  )
}

export default RepManagement
