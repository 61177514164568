import React, { useState } from 'react'
import styled from 'styled-components'
import TextMessage from '../TextMessage'
import { motion } from 'framer-motion'
import { syncAnimation, config } from '../animation'
import getRandomText from './getRandomText'
import useInterval from 'use-interval'

const Wrapper = styled.div`
  z-index: 3;
  position: absolute;
  width: 300px;
  height: 450px;
  right: 25%;
  margin-right: 10px;
  @media (max-width: 1024px) {
    position: relative;
    width: 100%;
    right: 0%;
    margin: 0px;
  }
`

const List = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1 1 auto;
  height: 450px;
  overflow: hidden;
  @media (max-width: 1024px) {
    position: absolute;
    width: 125px;
    right: 50%;
    margin-right: 30px;
    padding-bottom: 120px;
  }
`

const numberOfTexts = config.numberOfTexts
const createTextInterval = config.createTextInterval
const textLifetime = createTextInterval * numberOfTexts

const C = ({ syncAnimationControls }) => {
  const [items, setItems] = useState([getRandomText()])

  useInterval(() => {
    if (!document.hasFocus()) return
    const firstItems = items.slice(0, numberOfTexts)
    const updatedItems = [getRandomText(), ...firstItems]
    syncAnimationControls.start(syncAnimation)
    setItems(updatedItems)
  }, createTextInterval * 1000)

  return (
    <Wrapper>
      <List>
        {items.map(({ id, event, hospital, date }, index) => (
          <motion.div
            key={id}
            positionTransition={{ duration: config.createTextInterval / 3 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: [0, 0, 1, 1, 1, 0, 0] }}
            transition={{
              duration: textLifetime,
              times: [0, 0.025, 0.1, 0.2, 0.5, 0.6, 1],
            }}
          >
            <TextMessage
              id={id}
              event={event}
              hospital={hospital}
              date={date}
              index={index}
            />
          </motion.div>
        ))}
      </List>
    </Wrapper>
  )
}

export default C
