const testimonials = [
  {
    name: 'Rich G.',
    initials: 'RG',
    role: 'Sales Rep',
    text:
      'This is the first time I’ve seen hospitals adopt something that has REAL benefits to everyone involved. I know about add-ons before the OR staff does because the app picks it up the minute it enters the database. I don’t know how many times I call the OR manager and say “I see that Dr. X just added a hip revision for 4pm...” and they usually say they haven’t heard anything. Then they inevitably log into the schedule and check, confirming there is an add-on that we then prepare for. Prior to Torq, these situations happened all the time, and no one communicated or checked anything until the last minute, which put a huge burden on everyone involved.',
  },
  {
    name: 'Bill B.',
    initials: 'BB',
    role:
      'Clinical Analyst & Instructional Designer -Epic Certified in OpTime & Anesthesia',
    text:
      'Integrating with Torq Interface was straightforward and required 8 hours from our IT team. The Torq engineers knew exactly what they were doing and what was needed on my end to deliver a high quality interface. The process from initial conversations to go-live was quick and painless',
  },
  {
    name: 'Shawn A.',
    initials: 'SA',
    role: 'Sales Rep',
    text:
      "As an Ortho Trauma Rep for the past 10 years, one of the largest challenges is our daily schedule. While we often do have scheduled cases, a large portion of our cases tend to be add-ons, which are often added to the end of the day, or for the following day. Hospital staff struggle to tell me about these cases. Sometimes no one will call and tell me, other times I will hear from multiple people, which is a waste of their time. Because it is no one’s job, cases can very easily slip through the cracks leaving the hospital without proper equipment/implants or representation. \n Torq has been a game changer in my professional life. I now hear about the case as soon as it is booked via the app, and I have the ability to confirm that I now know of the case and will be in attendance. Getting notified via the app instead of having to call and check in 10 times a day with the front desk about case timing has been huge for me! If the case cancels, changes, or is delayed I get notified. I can't say enough good things about Torq, I only wish all of my hospital accounts were using it!",
  },
  {
    name: 'Dana J.',
    initials: 'DJ',
    role: 'Sales Rep',
    text:
      'This app has changed my life! I can’t imagine how I ever lived without it. I no longer have to go every morning to check the updated schedule and constantly call to confirm time changes as cases run late. Before it was impossible to get real-time information without calling the OR to check on the previous case progress. \n I hated having to bother at least two different staff members to find out what was going on. Now all I have to do is check my app every morning. As times change, I just get a notification. It frees up the staff and myself to focus on the job. It is also so helpful in planning tray deliveries and instrument needs months ahead of time. Since getting Torq, I have not had a single panicked phone call saying “doc added a case and it didn’t make the schedule, but they needed the tray yesterday.” I now see cases as soon as they’re added and can be proactive instead of reactivate. I can’t thank you enough for this. It’s truly a game changer. \n Thank you!',
  },
  {
    name: 'Henry A.',
    initials: 'HA',
    role: 'Sales Rep',
    text:
      'Torq is an invaluable product and should be in every hospital. Before Torq, I was often the last to know about changes. Add-ons or late updates to the schedule make it very difficult to ensure all necessary products are at the hospital and sterile. With Torq I provide better service with fewer texts and phone calls and have more confidence that I have exactly what the doctor has requested.',
  },
  {
    name: 'Sara L.',
    initials: 'SL',
    role: 'CPD Manager of a Level One Trauma AMC',
    text:
      'Torq has been a huge improvement to my efficiency as the manager of CPD. I no longer need to contact vendors for loaner equipment and with the click of a button I can email everyone associated with the case. I can see what is needed for cases well in advance and Torq updates with changes to the booking. Everytime something has come up that I felt could be a value add to the platform, the Torq team was readily available to discuss and implement if appropriate. ',
  },
  {
    name: 'Dr. John Halamka',
    initials: 'JH',
    role: 'President of the Mayo Clinic Platform',
    text:
      'The future belongs to cloud hosted services and mobile apps that surround the EHR, not replace it. EHR vendors will focus on compliance and billing while innovative apps will focus on usability and workflow.',
  },
  {
    name: 'Peter R., MSN, RN, CN IV',
    initials: 'PR',
    role:
      'Clinical Advisor for Neurosurgery, Ortho-Trauma, Podiatry, Spine, Maxillo Facial and ENT at a level one Trauma AMC in Boston MA',
    text:
      "Torq is a huge asset to me as the Clinical Advisor for the OR. The amount of time this saves me in one week is well over 3 hours. The time I spend communicating with company vendors/representatives is one of the most important responsibilities when coordinating scheduled cases. Not only does it free up my time, it is perfectly accurate in real-time. When cases are changed or cancelled, everyone involved is instantly notified via the Torq app. In the perioperative setting, Torq has helped me communicate more efficiently and effectively! \n Once the reps downloaded Torq, they all were amazed at the real-time accuracy it displayed. One of them said to me, “This is great, I don’t have to call you every Friday to check on next week's cases, it’s all right at my finger tips.” Torq’s a great tool to my success as a clinical advisor with Neurosurgery and Orthopedic cases. \n Thank you for the opportunity to trial and use Torq.",
  },
  {
    name: 'Adam L.',
    initials: 'AL',
    role:
      'Chief Information Officer of a Level One Trauma Academic Medical Center in Boston',
    text:
      'Torq has developed a valuable health IT solution that solves a major challenge in surgical planning—seamless communication and coordination between surgical device representatives and large care delivery institutions. \n The clinical and administrative staff within the surgical planning departments across our multiple campuses appreciate the reduced burden, easy-to-use interface, and increased operational efficiencies that have resulted from implementation of Torq at our two hospitals. It is addressing an immediate challenge and, as a result, we are seeing great positive feedback from day one. To quote one nurse leader, “[Torq] has been a great asset. It improves communication and helps to streamline the workflow in Orthopedics. I use it all the time.” \n Furthermore, with a breadth of experience in healthcare, specifically the medical device supply chain and planning, the team at Torq understands first-hand the pain points and challenges faced by both the vendors and providers.',
  },
  {
    name: 'Ben W.',
    initials: 'BW',
    role: 'Sales Rep',
    text:
      'The Torq Interface app has helped me improve how I conduct my day-to-day business. I work in the orthopedic trauma field, so cases are constantly being added and cancelled at a moment’s notice. Torq allows me to see in real-time the OR schedule for my cases, and has allowed me to manage my time more efficiently, which increases my day-to-day productivity. With Torqs schedule visualization, I get alerts about the exact procedure booking and equipment request from the surgeon. This allows me to provide the correct product at the correct time so the OR team has everything they need for a successful surgery. It’s a win-win! The Torq app is the first app I open in the morning, and the last app I check at night.',
  },
  {
    name: 'Shawn A.',
    initials: 'SA',
    role: 'Sales Rep',
    text:
      "As an Ortho Trauma Rep for the past 10 years, one of the largest challenges is our daily schedule. While we often do have scheduled cases, a large portion of our cases tend to be add-ons, which are often added to the end of the day, or for the following day. Hospital staff struggle to tell me about these cases. Sometimes no one will call and tell me, other times I will hear from multiple people, which is a waste of their time. Because it is no one’s job, cases can very easily slip through the cracks leaving the hospital without proper equipment/implants or representation. \n Torq has been a game changer in my professional life. I now hear about the case as soon as it is booked via the app, and I have the ability to confirm that I now know of the case and will be in attendance. Getting notified via the app instead of having to call and check in 10 times a day with the front desk about case timing has been huge for me! If the case cancels, changes, or is delayed I get notified. I can't say enough good things about Torq, I only wish all of my hospital accounts were using it!",
  },
]

const testimonialsWithId = testimonials.map((testimonial, id) => ({
  ...testimonial,
  id,
}))

export default testimonialsWithId
