import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'

class FaqCard extends React.Component {
  render() {
    const { id, category, question, answer } = this.props.faq
    return (
      <AnimatePresence exitBeforeEnter>
        <motion.div
          key={id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1.5 }}
        >
          <div className="faq">
            <div className="faq-category">{category}</div>
            <div className="faq-question">{question}</div>
            <div className="faq-answer">{answer}</div>
          </div>
        </motion.div>
      </AnimatePresence>
    )
  }
}

export default FaqCard
