import React from 'react'
import styled from 'styled-components'
import SectionHeader from '../shared/SectionHeader'
import EmailForm from './EmailForm'
import Section from './Section'

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  @media (max-width: 1280px) {
    width: 90%;
  }
`

const EmailFormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`

const Contact = () => (
  <Section>
    <SectionContent>
      <SectionHeader
        topTitle="Get in touch with us"
        bottomTitle="Ready to improve your vendor and tray management?"
        colorTheme="light"
      />
      <EmailFormWrapper>
        <EmailForm />
      </EmailFormWrapper>
    </SectionContent>
  </Section>
)

export default Contact
