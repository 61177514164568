import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../../lib/colors'

const Button = styled.div`
  font-size: 20px;
  font-weight: 400;
  color: var(--red);
  border: 1px solid ${colors.red};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  user-select: none;
  align-items: center;
  ${(props) =>
    props.styles &&
    css`
      ${props.styles}
    `};
`

const SymbolButton = React.memo((props) => {
  const { symbol, onClick, styles } = props

  return (
    <Button className="button animated" onClick={onClick} styles={styles}>
      {symbol}
    </Button>
  )
})

export default SymbolButton
