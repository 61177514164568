import React, { useReducer } from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import './styles.css'
import SectionHeader from '../shared/SectionHeader'
import Carousel from 'nuka-carousel'
import useWindowSize from '../../lib/hooks/useWindowSize'
import Card from './Card'
import bios from './bios'

import SymbolButton from '../shared/SymbolButton'

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${colors.white};
  color: ${colors.white};
  padding: 50px 0px;
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  @media (max-width: 1280px) {
    width: 90%;
  }
`

const slidesToShow = (width) => {
  if (width > 1400) return 4
  else if (width > 1100) return 3
  else if (width > 800) return 2
  return 1
}

const rightArrow = <span className="lnr lnr-arrow-right"></span>
const leftArrow = <span className="lnr lnr-arrow-left"></span>

const sideControlStyles = { height: '50px', width: '50px' }
const sideControls = {
  framePadding: '0px 75px 45px 75px',
  renderCenterRightControls: ({ nextSlide }) => (
    <SymbolButton
      symbol={rightArrow}
      onClick={nextSlide}
      styles={sideControlStyles}
    />
  ),
  renderCenterLeftControls: ({ previousSlide }) => (
    <SymbolButton
      symbol={leftArrow}
      onClick={previousSlide}
      styles={sideControlStyles}
    />
  ),
}

const bottomControls = {
  framePadding: '0px 0px 45px 0px',
  renderCenterRightControls: null,
  renderCenterLeftControls: null,
}

const Team = () => {
  const size = useWindowSize()
  const numOfSlides = slidesToShow(size.width)
  const carouselProps = numOfSlides === 1 ? bottomControls : sideControls
  const [forceUpdateCounter, forceUpdate] = useReducer((x) => x + 1, 0)
  const isClient = typeof window === 'object'

  return (
    <Section id="team">
      <SectionContent>
        <SectionHeader
          topTitle="Torq's Team"
          bottomTitle="Innovative Minds Working to Better Healthcare"
        />
        <div className="team-members">
          {isClient && (
            <Carousel
              {...carouselProps}
              slidesToShow={numOfSlides}
              x={forceUpdateCounter}
            >
              {bios.map((bio) => (
                <Card
                  key={bio.name}
                  bio={bio}
                  forceSectionUpdate={forceUpdate}
                />
              ))}
            </Carousel>
          )}
        </div>
      </SectionContent>
    </Section>
  )
}

export default Team
