import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "tray_management.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const Wrapper = styled.div`
  flex: 0 0 640px;
  padding: 60px;
  @media (max-width: 1280px) {
    width: 100%;
    max-width: 600px;
    padding: 10px;
    flex: 1 1 auto;
  }
`

const C = () => (
  <Wrapper>
    <Image />
  </Wrapper>
)

export default C
