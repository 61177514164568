import React from 'react'
import styled from 'styled-components'
import colors from '../../../lib/colors'

const StyledButton = styled.div`
  margin-top: 10px;
  color: ${colors.white};
  width: 234px;
  height: 60px;
  box-shadow: 0 10px 20px rgba(53, 67, 94, 0.5);
  border-radius: 5px;
  background-color: ${colors.red};
  font-size: 15px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Button = (props) => {
  return (
    <StyledButton
      className={props.className + 'animated button'}
      onClick={props.onClick}
    >
      {props.text}
    </StyledButton>
  )
}

export default Button
