import React from 'react'
import './styles.css'
import faqs from './faqs'
import styled from 'styled-components'
import colors from '../../lib/colors'
import SectionHeader from '../shared/SectionHeader'
import CategoryButton from './CategoryButton'
import FaqCard from './FaqCard'
import Button from '../shared/Button'
import Masonry from 'react-masonry-css'

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${colors.greylight};
  padding: 50px 0px;
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  @media (max-width: 1280px) {
    width: 90%;
  }
`

const Categories = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-bottom: 30px;
  @media (max-width: 1280px) {
    width: 90%;
    flex-wrap: wrap;
  }
`

const DEFAULT_TO_SHOW = 6
const faqCategories = [
  'All Questions',
  ...new Set(faqs.map(({ category }) => category)),
]

export default class EmailForm extends React.Component {
  state = { active_button: 'All Questions', faqs_to_show: DEFAULT_TO_SHOW }

  changeCategory = (buttonName) =>
    this.setState({ active_button: buttonName, faqs_to_show: DEFAULT_TO_SHOW })

  loadMore = () =>
    this.setState({ faqs_to_show: this.state.faqs_to_show + DEFAULT_TO_SHOW })

  render() {
    const { active_button, faqs_to_show } = this.state

    const faqsInCategory =
      active_button === 'All Questions'
        ? faqs
        : faqs.filter((faq) => faq.category === active_button)
    const showMoreButton = faqs_to_show < faqsInCategory.length

    return (
      <Section id="faq">
        <SectionContent>
          <SectionHeader
            topTitle="Your Questions Answered"
            bottomTitle="Frequently Asked Questions"
          />
          <Categories>
            {faqCategories.map((category) => (
              <CategoryButton
                key={category}
                text={category}
                isActive={active_button === category}
                click={() => this.changeCategory(category)}
              />
            ))}
          </Categories>

          <Masonry
            breakpointCols={{ default: 3, 1280: 2, 767: 1 }}
            className="faqs-grid"
            columnClassName="faqs-grid_column"
          >
            {faqsInCategory.slice(0, faqs_to_show).map((faq) => (
              <FaqCard key={faq.id} faq={faq} />
            ))}
          </Masonry>

          {showMoreButton && (
            <Button text="Load More Questions..." onClick={this.loadMore} />
          )}
        </SectionContent>
      </Section>
    )
  }
}
