import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../../../lib/colors'

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 100px;
  margin: 20px 0px 20px 0px;
  @media (max-width: 1280px) {
    text-align: center;
  }
`

const TopTitle = styled.div`
  flex: 1 1 10px;
  display: flex;
  align-items: center;
  height: 12px;
  color: ${colors.blue};
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: 4.8px;
  @media (max-width: 1280px) {
    flex: 1 1 100%;
    line-height: 24px;
    padding-bottom: 5px;
    font-size: 12px;
  }
  ${props =>
    props.theme === 'light' &&
    css`
      color: ${colors.white};
    `};
`
const BottomTitle = styled.div`
  flex: 1 1 35px;
  color: #2a3449;
  font-size: 25px;
  font-weight: 700;
  line-height: 55px;
  @media (max-width: 1280px) {
    line-height: 35px;
    flex: 1 1 100%;
  }
  ${props =>
    props.theme === 'light' &&
    css`
      color: ${colors.white};
    `};
`

const SectionHeader = props => {
  const { colorTheme, topTitle, bottomTitle } = props
  const theme = colorTheme || 'dark'

  return (
    <Section>
      <TopTitle theme={theme}>{topTitle}</TopTitle>
      <BottomTitle theme={theme}>{bottomTitle}</BottomTitle>
    </Section>
  )
}

export default SectionHeader
