import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const WrapperMotion = ({ className, children }) => (
  <motion.div
    className={className}
    whileHover={{ scale: 1.05, transition: { duration: 0.25 } }}
    whileTap={{ opacity: 1, transition: { duration: 0.25 } }}
  >
    {children}
  </motion.div>
)

const Wrapper = styled(WrapperMotion)`
  flex: 0 0 250px;
  max-height: 300px;
  padding: 0px 20px;
  filter: grayscale(100%);
  opacity: 0.5;
  transform: translateZ(0);
  cursor: pointer;
  &:hover {
    opacity: 1;
    filter: grayscale(0%);
    filter: brightness(100%);
  }
`

export default Wrapper
