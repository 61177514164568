import React from 'react'
import Ahren from './Headshots/Ahren'
import Charlie from './Headshots/Charlie'
import Joanna from './Headshots/Joanna'
import Dan from './Headshots/Dan'
import John from './Headshots/John'
import Scott from './Headshots/Scott'
import Steve from './Headshots/Steve'

const bios = [
  {
    name: 'Joanna Geisinger',
    bio:
      'Joanna has worked for almost a decade in medical sales, serving some of the world’s foremost medical equipment companies, like Smith & Nephew and Zimmer Biomet. Early in her career, Joanna became preoccupied with fixing the poor communication endemic in surgical scheduling, a problem she faced firsthand. Eventually, in 2016, she left her job in medical sales to commit to the Two-Year MBA Program at Babson College F.W. Olin Graduate School of Business. Now, Joanna leads Torq on its mission to revolutionize communication between hospitals and their vendors and to repurpose savings for enhanced patient care.',
    image: <Joanna />,
    role: 'Co-Founder and CEO',
  },
  {
    name: 'Dan King',
    bio:
      'Dan worked for nine years as a surgical device representative for Zimmer Biomet, serving Boston’s most noted academic centers as well as Massachusetts community hospitals. Dan became interested in joining the Torq team after two of his hospitals embraced the product. He was impressed and excited to see how quickly Torq took off in hospitals, and how much it was appreciated by both sides of the supply chain (i.e. medical staff and vendors), considering hospitals are uniquely challenging places to implement large-scale operational changes. In January 2019, Dan left ZB to officially join Torq. ',
    image: <Dan />,
    role: 'Co-Founder & VP',
  },
  {
    name: 'Charlie Offenbacher',
    bio:
      'Charlie worked as a program manager on petabyte-scale data processing at Microsoft before starting his own successful company, Book Rocket, and serving as its CTO for several years. As CTO of Torq, Charlie co-manages all platform software alongside Lead Engineer, Ahren Baesler.',
    image: <Charlie />,
    role: 'Co-Founder & CTO',
  },
  {
    name: 'Ahren Baesler',
    bio:
      'Ahren worked in the audit and assurance industry for three years post graduation before helping co-found and grow a new company, Book Rocket. While there, he was in charge of overseeing core business operations, developing the custom operational systems and tools that powered the business, and managing key business strategy with his partners. At Torq, Ahren co-manages all platform software across front facing services, mobile apps, and backend systems.',
    image: <Ahren />,
    role: 'Lead Engineer',
  },
  {
    name: 'John D. Halamka, MD, MS',
    bio:
      'Dr. John Halamka is world-renowned for his influence on healthcare innovation. Preceding his current role as President of the Mayo Clinic Platform, he was the Chief Information Officer of the Beth Israel Deaconess Medical Center and Harvard Medical School, Chairman of the New England Health Electronic Data Interchange Network, CEO of MA-SHARE, Chair of the US Healthcare Information Technology Standards Panel, and a practicing Emergency Physician.',
    image: <John />,
    role: 'Advisor',
  },
  {
    name: 'Steve Lindseth',
    bio:
      'Steve has founded companies in healthcare devices, technology enabled services, digital media, and enterprise software. Steve is a general partner at the University of Colorado Innovation Fund and a senior advisor to Triple Tree. He is also on the board of Cleveland Clinic Innovations and consultant to Partners Healthcare Innovation and Ariadne Labs.',
    image: <Steve />,
    role: 'Advisor',
  },
  // {
  //   name: 'Mark Augusti',
  //   bio:
  //     'Mark is a proven executive leader, having held positions as President, CEO, and Board Member in both public and private companies. Currently, Mark sits as the Chief Executive Officer at Conformis, an orthopedic device company that specializes in custom implants.',
  //   image: <Mark />,
  //   role: 'Advisor',
  // },
  {
    name: 'Scott Sigman, MD',
    bio:
      'Dr. Scott Sigman is a practicing orthopedic surgeon who specializes in sports medicine and knee and shoulder surgery. He is also the Chief Medical Officer of Ortho Lazer, a company devoted to opioid-sparing treatment, which is his true professional passion.',
    image: <Scott />,
    role: 'Advisor',
  },
]

export default bios
