import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Wrapper from './Wrapper'

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "hospitals/hospital_southcoast.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return <Img fluid={data.placeholderImage.childImageSharp.fluid} />
}

const CustomWrapper = styled(Wrapper)`
  margin-top: 15px;
`

const C = React.memo(() => (
  <CustomWrapper>
    <Image />
  </CustomWrapper>
))

export default C
