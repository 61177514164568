import React from 'react'
import { slide as Menu } from 'react-burger-menu'
import colors from '../../lib/colors'
import StyledLink from '../shared/StyledLink'

class Hamburger extends React.Component {
  state = { menuOpen: false }

  handleStateChange(state) {
    this.setState({ menuOpen: state.isOpen })
  }

  closeMenu = () => {
    this.setState({ menuOpen: false })
  }

  showSettings(event) {
    event.preventDefault()
  }
  render() {
    return (
      <Menu
        right
        styles={styles}
        isOpen={this.state.menuOpen}
        pageWrapId={'page-wrap'}
        outerContainerId={'outer-container'}
        onStateChange={(state) => this.handleStateChange(state)}
      >
        <StyledLink
          className="menu-item link"
          href="#rep-management"
          onClick={this.closeMenu}
        >
          Rep Management
        </StyledLink>
        <StyledLink
          className="menu-item link"
          href="#tray-management"
          onClick={this.closeMenu}
        >
          Tray Management
        </StyledLink>
        <StyledLink
          className="menu-item link"
          href="#testimonials"
          onClick={this.closeMenu}
        >
          Testimonials
        </StyledLink>
        <StyledLink
          className="menu-item link"
          href="#team"
          onClick={this.closeMenu}
        >
          Team
        </StyledLink>
        <StyledLink
          className="menu-item link"
          href="#faq"
          onClick={this.closeMenu}
        >
          FAQs
        </StyledLink>
        <StyledLink
          className="menu-item link"
          href="#contact"
          onClick={this.closeMenu}
        >
          Contact Us
        </StyledLink>
      </Menu>
    )
  }
}

var styles = {
  bmBurgerButton: {
    position: 'absolute',
    width: '30px',
    height: '25px',
    right: '30px',
    top: '30px',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
  },
  bmMenu: {
    background: colors.bluedark,
    padding: '2.5em 1.5em 0',
    fontSize: '1.15em',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: colors.white,
    padding: '0.8em',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bmItem: {
    display: 'inline-block',
    color: colors.white,
    textDecoration: 'none',
    marginBottom: '10px',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
  },
}

export default Hamburger
