import React from 'react'
import styled from 'styled-components'
import HeaderLink from './HeaderLink'
import StyledLink from '../shared/StyledLink'

const Links = styled.div`
  display: flex;
  flex-direction: row;
  flex: 0 1 700px;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  @media (max-width: 1280px) {
    display: none;
  }
`

const C = () => (
  <Links>
    <HeaderLink className="link">
      <StyledLink href="#rep-management">Rep Management</StyledLink>
    </HeaderLink>
    <HeaderLink className="link">
      <StyledLink href="#tray-management">Tray Management</StyledLink>
    </HeaderLink>
    <HeaderLink className="link">
      <StyledLink href="#testimonials">Testimonials</StyledLink>
    </HeaderLink>
    <HeaderLink className="link">
      <StyledLink href="#team">Team</StyledLink>
    </HeaderLink>
    <HeaderLink className="link">
      <StyledLink href="#faq">FAQs</StyledLink>
    </HeaderLink>
  </Links>
)

export default C
