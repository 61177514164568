import React from 'react'
import SymbolButton from '../../shared/SymbolButton'

class Card extends React.PureComponent {
  state = { isOpen: false, height: '150px', moreOrLess: '+' }

  onClick = () => {
    if (!this.state.isOpen) {
      this.setState({ isOpen: true, height: '1000px', moreOrLess: '-' }, () =>
        this.props.forceSectionUpdate(),
      )
    } else {
      this.setState({ isOpen: false, height: '150px', moreOrLess: '+' }, () =>
        this.props.forceSectionUpdate(),
      )
    }
  }

  render() {
    const { image, name, role, bio } = this.props.bio

    const bioStyle = { maxHeight: this.state.height }
    const bioClassName = !this.state.isOpen
      ? 'team-member-bio clamped-5'
      : 'team-member-bio'

    return (
      <div className="team-member">
        <div className="team-member-image">{image}</div>
        <div className="team-member-name">{name}</div>
        <div className="team-member-role">{role}</div>
        <div className={bioClassName} style={bioStyle}>
          {bio}
        </div>
        <SymbolButton
          symbol={this.state.moreOrLess}
          onClick={this.onClick}
          styles={{ marginTop: '10px' }}
        />
      </div>
    )
  }
}

export default Card
