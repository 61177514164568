import React from 'react'
import colors from '../../lib/colors'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  align-items: center;
  min-height: 80px;
`

const InitialsWrapper = styled.div`
  flex: 0 0 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  text-align: left;
  justify-content: center;
  max-width: 65%;
`

const Name = styled.div`
  color: ${colors.greydark};
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
`

const Role = styled.div`
  color: ${colors.greylighttext};
  font-size: 11px;
  font-weight: 400;
  line-height: 15px;
`

const Initials = styled.div`
  font-size: 20px;
  color: ${colors.greydark};
  border: 1px solid ${colors.greydark};
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  user-select: none;
  align-items: center;
`

const C = props => {
  const { name, role, initials } = props.testimonial
  return (
    <Wrapper>
      <InitialsWrapper>
        <Initials>{initials}</Initials>
      </InitialsWrapper>

      <Content>
        <Name>{name}</Name>
        <Role>{role}</Role>
      </Content>
    </Wrapper>
  )
}

export default C
