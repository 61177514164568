import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import colors from '../../lib/colors'
import { Media } from '../Media'

import BackgroundImage from 'gatsby-background-image'

const sectionStyles = {
  display: 'flex',
  color: colors.white,
  flexDirection: 'row',
  justifyContent: 'space-around',
  backgroundColor: colors.white,
  padding: '75px 0 25px',
}

const desktopStyles = {
  width: '100%',
  backgroundSize: '767px',
  backgroundColor: 'transparent',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'right -125px top 75px',
}

const DesktopSection = ({ children, className }) => {
  const { center } = useStaticQuery(
    graphql`
      query {
        center: file(relativePath: { eq: "blue_shape.png" }) {
          childImageSharp {
            fluid(quality: 100, maxWidth: 767) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `,
  )

  const backgroundFluidImageStack = [center.childImageSharp.fluid].reverse()

  return (
    <BackgroundImage
      Tag="section"
      id="video"
      className={className}
      style={{ ...desktopStyles, ...sectionStyles }}
      fluid={backgroundFluidImageStack}
    >
      {children}
    </BackgroundImage>
  )
}

const MobileSection = ({ children }) => {
  return <div style={sectionStyles}>{children}</div>
}

const Section = ({ children }) => (
  <>
    <Media at="sm">
      <MobileSection>{children}</MobileSection>
    </Media>
    <Media greaterThanOrEqual="lg">
      <DesktopSection>{children}</DesktopSection>
    </Media>
  </>
)

export default Section
