import React from 'react'
import { useState, useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import colors from '../../lib/colors'
import Illustration from './Illustration'
import Icon from './Icon'
import Button from '../shared/Button'
import StyledLink from '../shared/StyledLink'

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${colors.blue};
  color: ${colors.white};
  padding: 50px 0px;
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  @media (max-width: 1280px) {
    flex-direction: column-reverse;
    justify-content: center;
    width: 90%;
  }
`

const ContentColumn = styled.div`
  display: flex;
  flex: 0 0.1 600px;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1280px) {
    flex: 0 1 100%;
    padding-bottom: 20px;
  }
`

const Content = styled.div`
  flex: 0 0 400px;
  @media (max-width: 1280px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

const Header = styled.div`
  padding: 10px 0px;
  display: flex;
  flex-direction: row;
  @media (max-width: 1280px) {
    flex-direction: column;
    align-items: center;
    align-content: center;
  }
`

const HeaderText = styled.div`
  padding-left: 20px;
  font-size: 16px;
  font-weight: 700;
  line-height: 40px;
  text-transform: uppercase;
  letter-spacing: 4.8px;
`
const HeaderHRWrapper = styled.div`
  flex: 0 0 250px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 1280px) {
    display: none;
  }
`

const HeaderHR = styled.div`
  flex: 0 0 2px;
  background-color: ${colors.white};
`

const HeroText = styled.div`
  padding: 10px 0px;
  width: 450px;
  font-size: 42px;
  font-weight: 700;
  line-height: 55px;
  @media (max-width: 1280px) {
    width: 100%;
    text-align: center;
  }
`

const Details = styled.div`
  padding: 20px 0px;
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  @media (max-width: 1280px) {
    flex: 0 1 100%;
    text-align: center;
  }
`

const contentAnimationProps = (shown) => ({
  animate: shown ? 'visible' : 'hidden',
  variants: {
    visible: { opacity: 1, x: 0 },
    hidden: { opacity: 0, x: -150 },
  },
  transition: { duration: 1, delay: 0 },
})

const TrayManagement = () => {
  const [shown, setShown] = useState(false)
  const [ref, inView] = useInView({ threshold: 0.33 })
  useEffect(() => setShown(shown || inView), [inView])

  return (
    <Section id="tray-management" ref={ref}>
      <SectionContent>
        <ContentColumn>
          <motion.div {...contentAnimationProps(shown)}>
            <Content>
              <Header>
                <Icon />
                <HeaderText>Tray Management</HeaderText>
                <HeaderHRWrapper>
                  <HeaderHR />
                </HeaderHRWrapper>
              </Header>
              <HeroText>Simplified Loaner Tray Management</HeroText>
              <Details>
                Gain visibility into the status of loaned equipment and track
                vendor compliance with pick-up and drop-off windows using Torq’s
                inventory feature. With a simple label printer and kiosk in each
                hospital, reps mark trays “in” and “out” as well as tag trays
                with up-to-date case information. These labels are printed
                through the Torq portal, so you can check your computer or
                smartphone to see exactly what reps have done, and when.
              </Details>
              <StyledLink href="#faq">
                <Button text="Learn More" />
              </StyledLink>
            </Content>
          </motion.div>
        </ContentColumn>
        <Illustration />
      </SectionContent>
    </Section>
  )
}

export default TrayManagement
