const faqs = [
  {
    category: 'Hospital IT',
    question: 'Is this HIPAA compliant?',
    answer:
      'Yes, Torq is completely HIPAA compliant. Namely, it contains no PHI. The platform is actually a huge improvement in HIPAA security, since, right now in the field, vendors are receiving sensitive case details through unsecured channels (e.g., calls, texts, emails) with no oversight.',
  },
  {
    category: 'Hospital IT',
    question: 'What is the IT burden on the hospital?',
    answer:
      "To minimize any IT burden, Torq is built to integrate into each hospital however is preferred by the hospital’s IT department. We work with multiple integration firms and have successfully implemented via HL7, SFTP flat-file transfer, database-connected daemons, and web scraping, depending on the EHR and the hospital's IT team's request.",
  },
  {
    category: 'Hospital IT',
    question: 'What is the process to go live?',
    answer:
      'Once the decision is made to implement Torq, we sign all necessary contracts, then set up a meeting with the IT department to determine the best mode of integration into the EHR. Next, we are added to the IT queue and wait our turn in line (this is usually the longest part of the project). Once we start working actively with the IT department, it usually takes 8-12 hours of IT time over 1-2 weeks to go live.',
  },
  {
    category: 'Hospital OR',
    question:
      'Does the OR staff have control over the cases visible to vendors?',
    answer:
      'The OR staff has complete authority over which cases reps see. Torq has a rules engine to automatically pair reps to cases. We work with OR staff to set up these pairing rules. There is also a portal that allows staff to manually pair and/or unpair reps at any time.',
  },
  {
    category: 'Hospital OR',
    question: 'What happens if reps don’t use the app?',
    answer:
      'Torq has almost perfect vendor compliance seeing as the real-time updates provide so much benefit to them.',
  },
  {
    category: 'Hospital OR',
    question: 'Do reps see the entire schedule?',
    answer:
      'No, reps only see cases involving products they cover. We have automatic pairing rules that make these assignments, and OR staff has the option to manually add or remove reps at any time.',
  },
  {
    category: 'Hospital OR',
    question: 'What if a rep is not on Torq?',
    answer:
      'Torq reaches out to all reps each hospital provides in order to get them on-boarded. If a rep is new to the hospital, there is an easy way for OR staff to contact Torq, at which point we can quickly add the new rep to our data base and provide them with their unique username/password.',
  },
  {
    category: 'Hospital OR',
    question: 'What happens if a staff member or rep leaves?',
    answer:
      'We revoke Torq access as soon as we are notified a staff or rep is no longer with the hospital.',
  },
  {
    category: 'Hospital OR',
    question: 'How are reps onboarded?',
    answer:
      'Torq asks the OR for a list of reps that cover the hospital as well as their companies and product segment (recon, trauma, spine, etc.). Next, we set up pairing rules to assign the appropriate cases to reps. Torq individually emails reps with login information and works with the OR and reps to customize pairing rules.',
  },
  {
    category: 'Hospital OR',
    question: 'How much staff time is needed for inservicing?',
    answer:
      'Staff users are usually up-and-running after about 30 minutes of inservicing.',
  },
  {
    category: 'Hospital OR',
    question: 'What happens if Torq or the EHR has a problem?',
    answer:
      'We have a robust monitoring system that alerts us if either Torq or the hospital EHR is not working properly. If it continues for any sustained period of time, we reach out to the hospital to understand the situation. If the error could cause a prolonged problem for reps, we send out notifications informing reps that information on Torq is not reliable due to the outage. While Torq works on the error, vendors can still get updates manually. Once the error is fixed, we notify vendors right away.',
  },
  {
    category: 'Reps',
    question: 'Will reps be forced to pay for Torq?',
    answer:
      'Reps will never be forced to pay for Torq. We are a company founded by former reps forced to pay for credentialing, paper scrubs, and the rest, all while receiving nothing in return, except the right to do our jobs. We want to provide a valuable tool at no cost to reps. There may be premium features available in the future, but upgrading will not be required.',
  },
  {
    category: 'Reps',
    question:
      'How are reps paired to cases? Does someone at the hospital need to add them?',
    answer:
      'Reps are paired to cases by an automatic rules engine that requires no staff action. The rules are based on the products each hospital covers and surgeons that hospital works with. We will work with your hospital and OR to make these pairings accurate and reliable.',
  },
  {
    category: 'Reps',
    question:
      'How are reps assigned if surgeons do not specify product requests in their notes?',
    answer:
      'Torq can set default rules that pair reps based on surgeon preference for particular procedures, if applicable. In such a case, the OR staff helps Torq identify and create these rules.',
  },
  {
    category: 'Reps',
    question: 'Is Torq accurate?',
    answer:
      'Torq is a 100% accurate reflection of the hospital EHR (Epic, Cerner, Meditech, etc.). We simply pull data out of the EHR and send it to your Torq app in real time. Surgical scheduling data is useless if not perfectly accurate. To ensure this, we start with a 1-2 week roll-in period and ask vendors to continue business as usual while also keeping a check on Torq’s data. A successful roll in period will indicate to us that we are ready to launch as well as help you gain confidence in our accuracy.',
  },
]

const faqsWithId = faqs.map((faq, id) => ({ ...faq, id }))

export default faqsWithId
