import React from 'react'
import styled from 'styled-components'
import colors from '../../../lib/colors'
import TestimonialAuthor from '../TestimonialAuthor'
import SymbolButton from '../../shared/SymbolButton'

const Testimonial = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  padding: 50px 25px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.08);
  text-align: center;
  border-radius: 5px;
  color: ${colors.greydark};
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  align-items: center;
  justify-content: space-between;
`

const TestimonialHR = styled.div`
  flex: 0 0 1px;
  width: 150px;
  margin: 20px 0px;
  background-color: ${colors.blue};
`

const Paragraph = styled.p`
  margin-bottom: 15px;
`

class Card extends React.PureComponent {
  state = { isOpen: false, height: '100px', moreOrLess: '+' }

  smoothUpdateCarousel = () => {
    this.props.forceSectionUpdate()
  }

  onClick = () => {
    if (this.state.isOpen) {
      this.setState(
        { isOpen: false, height: '100px', moreOrLess: '+' },
        this.smoothUpdateCarousel,
      )
    } else {
      this.setState(
        { isOpen: true, height: '1000px', moreOrLess: '-' },
        this.smoothUpdateCarousel,
      )
    }
  }

  render() {
    const { name, text } = this.props.testimonial
    const testimonialStyle = {
      maxHeight: this.state.height,
      overflow: 'hidden',
    }

    const testimonialClassName = !this.state.isOpen
      ? 'testimonial-text clamped-4'
      : 'testimonial-text'

    const spacedTestimonial = text
      .split('\n')
      .map((p, i) => <Paragraph key={i}>{p}</Paragraph>)

    return (
      <Testimonial key={name}>
        <div className={testimonialClassName} style={testimonialStyle}>
          {spacedTestimonial}
        </div>
        <SymbolButton
          symbol={this.state.moreOrLess}
          onClick={this.onClick}
          styles={{ marginTop: '10px' }}
        />
        <TestimonialHR />
        <TestimonialAuthor testimonial={this.props.testimonial} />
      </Testimonial>
    )
  }
}

export default Card
