import React from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'

const WrapperMotion = ({ className, children }) => (
  <motion.div
    className={className}
    whileHover={{ scale: 1.05, transition: { duration: 0.25 } }}
  >
    {children}
  </motion.div>
)

const Wrapper = styled(WrapperMotion)`
  padding-top: 15px;
  width: 153px;
  @media (max-width: 1440px) {
    width: 115px;
  }
`

export default Wrapper
