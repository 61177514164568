import { uuid } from 'uuidv4'
import ChecklistIcon from './icons/ChecklistIcon'
import ScheduleIcon from './icons/ScheduleIcon'
import ContactIcon from './icons/ContactIcon'

const event = [
  { name: 'New Case', message: 'New case on ', Icon: ContactIcon },
  { name: 'Time Change', message: 'Time change on ', Icon: ScheduleIcon },
  { name: 'Cancellation', message: 'Cancellation on ', Icon: ScheduleIcon },
  {
    name: 'Case Reminder',
    message: 'Confirm case on ',
    Icon: ChecklistIcon,
  },
]

const hospitals = [
  'Brigham',
  'Faulkner',
  'Beth-Israel',
  'Southcoast',
  `St. Elizabeth's`,
]

const choose = (array) => array[(array.length * Math.random()) | 0]

const randomDate = (days) =>
  new Date(new Date().getTime() + Math.random() * days * 24 * 60 * 60 * 1000)

const getRandomText = () => ({
  event: choose(event),
  hospital: choose(hospitals),
  date: randomDate(30),
  id: uuid(),
})

export default getRandomText
