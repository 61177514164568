import React from 'react'
import colors from '../../lib/colors'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 63px;
  height: 63px;
  padding: 10px;
  border-radius: 5px;
  background-color: ${colors.greylight};
  @media (max-width: 1024px) {
    width: 33px;
    height: 33px;
    border-radius: 2px;
    padding: 5px;
  }
`

const C = ({ event }) => {
  return (
    <Wrapper>
      <event.Icon style={{ width: '50px' }} />
    </Wrapper>
  )
}

export default C
