import React from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import StyledLink from '../shared/StyledLink'
import { useAnimation } from 'framer-motion'

import MobileHeroImage from './MobileHeroImage'
import TextMessageWrapper from './TextMessageWrapper'
import Section from './Section'
import Logo from './Logo'
import Sync from './Sync'
import Links from './Links'

import Button from '../shared/Button'

const paddingLeft = '50px'

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 80%;
  @media (max-width: 1280px) {
    width: 90%;
  }
`

const StyledHeader = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  height: 120px;
  align-items: center;
  @media (max-width: 1280px) {
    height: initial;
    padding-bottom: 40px;
  }
`

const LogoSection = styled.div`
  flex: 1 1;
  padding-left: ${paddingLeft};
  padding-right: 20px;
  @media (max-width: 1280px) {
    padding: 0;
  }
  @media (max-width: 768px) {
    padding: 5px;
  }
`

const LogoWrapper = styled.div`
  width: 153px;
  @media (max-width: 1280px) {
    padding-top: 15px;
  }
`

const ContactLinkWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 0 1 550px;
  align-items: center;
  z-index: 3;
  @media (max-width: 1280px) {
    display: none;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 1024px) {
    position: relative;
    flex-direction: column;
    align-items: center;
  }
`

const TextHeader = styled.div`
  font-size: 50px;
  font-weight: 700;
  line-height: 70px;
  @media (max-width: 1440px) {
    font-size: 38px;
    line-height: 51px;
  }
  @media (max-width: 1024px) {
    font-size: 28px;
    line-height: 34px;
  }
`
const TextSubHeader = styled.div`
  margin-top: 1rem;
  font-size: 17px;
  font-weight: 600;
  line-height: 30px;
  width: 600px;
  @media (max-width: 1440px) {
    width: 350px;
  }
  @media (max-width: 1366px) {
    font-size: 16px;
    line-height: 28px;
    width: 300px;
  }
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 28px;
    text-align: center;
    width: initial;
  }
`

const ContactButton = styled(Button)`
  width: 133px;
  height: 48px;
  font-weight: 600;
  margin-right: 40px;
  margin-top: 0px;
`

const UseItForFreeButton = styled(Button)`
  margin-top: 2rem;
  height: 71px;
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 1440px) {
    font-size: 15px;
  }
  @media (max-width: 1024px) {
    text-align: center;
    align-self: center;
  }
`

const UseItForFreeLink = styled(StyledLink)`
  display: flex;
  @media (max-width: 1024px) {
    justify-content: center;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 150px 40px;
  width: 700px;
  flex-grow: 0;
  color: ${colors.white};
  height: 800px;
  @media (max-width: 1440px) {
    width: 500px;
  }
  @media (max-width: 1366px) {
    width: 450px;
  }
  @media (max-width: 1280px) {
    width: 400px;
    padding: 100px 20px 100px 40px;
  }
  @media (max-width: 1024px) {
    padding: 0px;
    width: 80%;
    height: initial;
    text-align: center;
    justify-content: center;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Header = () => {
  const syncAnimationControls = useAnimation()
  return (
    <Section>
      <SectionContent>
        <StyledHeader>
          <LogoSection>
            <LogoWrapper>
              <Logo />
            </LogoWrapper>
          </LogoSection>
          <Links />
          <ContactLinkWrapper>
            <StyledLink href="#contact">
              <ContactButton text="Contact Us" />
            </StyledLink>
          </ContactLinkWrapper>
        </StyledHeader>
        <Content>
          <TextContent>
            <TextHeader>
              You just found a better way to manage your surgical reps.
            </TextHeader>
            <TextSubHeader>
              Join over 100 hospitals already using Torq's EHR-integrated
              platform to streamline surgical scheduling and eliminate
              back-and-forth emails, calls, and texts.
            </TextSubHeader>
            <UseItForFreeLink href="#contact">
              <UseItForFreeButton text="Use It For Free" />
            </UseItForFreeLink>
          </TextContent>
          <MobileHeroImage />
          <Sync />
          <TextMessageWrapper syncAnimationControls={syncAnimationControls} />
        </Content>
      </SectionContent>
    </Section>
  )
}

export default Header
