import React from 'react'
import colors from '../lib/colors'
import styled from 'styled-components'
import Header from '../components/Header'
import Hospitals from '../components/Hospitals'
import RepManagement from '../components/RepManagement'
import TrayManagement from '../components/TrayManagement'
import Testimonials from '../components/Testimonials'
import Team from '../components/Team'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import FAQ from '../components/FAQ'
import { Helmet } from 'react-helmet'
import Hamburger from '../components/Header/Hamburger'
import SEO from '../components/seo'
import Video from '../components/Video'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${colors.greylight};
  justify-content: center;
  width: 100%;
  overflow-x: hidden;
`

const ContentWrapper = styled.div`
  box-shadow: 0 0 5px 4px rgba(0, 0, 0, 0.15);
`

const IndexPage = () => {
  return (
    <Container id="outer-container">
      <SEO title="Torq Interface" />
      <Helmet>
        <script
          src="https://kit.fontawesome.com/e1a570da00.js"
          crossOrigin="anonymous"
        ></script>
        <script
          type="text/javascript"
          id="hs-script-loader"
          async={true}
          defer={true}
          src="https://js.hs-scripts.com/5437949.js"
        ></script>
      </Helmet>
      <Hamburger />
      <ContentWrapper id="page-wrap">
        <Header />
        <Hospitals />
        <Video />
        <RepManagement />
        <TrayManagement />
        <Testimonials />
        <Team />
        <FAQ />
        <Contact />
        <Footer />
      </ContentWrapper>
    </Container>
  )
}

export default IndexPage
