import React from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import Section from './Section'
import myImagePath from '../../images/ipad.png'

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  width: 80%;
  @media (max-width: 1280px) {
    width: 90%;
  }
`

const TextHeader = styled.div`
  padding: 10px 0px;
  color: #2a3449;
  font-size: 42px;
  font-weight: 700;
  line-height: 55px;
  @media (max-width: 1280px) {
    width: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 36px;
  }
`
const TextSubHeader = styled.div`
  display: flex;
  flex: 1 1 30px;
  align-items: center;
  color: ${colors.greydark};
  text-align: center;
  font-size: 17px;
`

const VideoContainer = styled.div`
  width: 100%;
  height: 100%;
  max-width: 1021px;
  margin: 0 auto;
`
const VideoWrapper = styled.div`
  position: relative;
  padding-top: 25px;
  padding-bottom: 67.5%;
  height: 0;

  iframe {
    box-sizing: border-box;
    background: url(${myImagePath}) center center no-repeat;
    background-size: contain;
    padding: 11.1% 9% 12%;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Video = () => {
  return (
    <Section>
      <SectionContent>
        <TextHeader>Watch Our Video</TextHeader>
        <TextSubHeader>
          Hear from doctors, nurses, and vendors about the power and impact of
          Torq.
        </TextSubHeader>
        <VideoContainer>
          <VideoWrapper>
            <iframe
              src="https://www.youtube.com/embed/6JSCdepSWB0"
              frameBorder="0"
              allowFullScreen
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </VideoWrapper>
        </VideoContainer>
      </SectionContent>
    </Section>
  )
}

export default Video
