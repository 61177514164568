import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { Media } from '../Media'

const styles = {
  mobile: {
    background: {
      position: 'absolute',
      width: '320px',
      bottom: '0px',
      zIndex: 2,
      marginLeft: '-145px',
      left: '50%',
    },
    head: {
      position: 'absolute',
      width: '140px',
      zIndex: 3,
      bottom: '179px',
      left: '50%',
      marginLeft: '-27px',
      opacity: 1,
    },
    arm: {
      position: 'absolute',
      width: '183px',
      zIndex: 3,
      bottom: '8px',
      left: '50%',
      marginLeft: '-130px',
    },
  },
  desktop: {
    background: {
      position: 'absolute',
      width: '500px',
      bottom: '160px',
      zIndex: 3,
      right: '25%',
      marginRight: '-310px',
    },
    head: {
      position: 'absolute',
      width: '225px',
      zIndex: 4,
      bottom: '435px',
      right: '25%',
      marginRight: '-217px',
    },
    arm: {
      position: 'absolute',
      width: '286px',
      zIndex: 4,
      bottom: '170px',
      right: '25%',
      marginRight: '-120px',
    },
    grid: {
      position: 'absolute',
      width: '130px',
      zIndex: 1,
      bottom: '70px',
      right: '25%',
      marginRight: '20px',
    },
    circles: {
      position: 'absolute',
      width: '800px',
      bottom: '0px',
      right: '25%',
      marginRight: '-450px',
    },
  },
}

const Background = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "nurse/lady.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      head: file(relativePath: { eq: "nurse/head.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      arm: file(relativePath: { eq: "nurse/arm.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      grid: file(relativePath: { eq: "grid_white.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      circles: file(relativePath: { eq: "nurse/circles.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      checklist: file(relativePath: { eq: "icons/icon_checklist.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <Media at="sm">
        <Img
          fluid={data.background.childImageSharp.fluid}
          style={styles.mobile.background}
        />
        <Img
          fluid={data.head.childImageSharp.fluid}
          style={styles.mobile.head}
        />
        <Img fluid={data.arm.childImageSharp.fluid} style={styles.mobile.arm} />
      </Media>
      <Media greaterThanOrEqual="lg">
        <Img
          fluid={data.background.childImageSharp.fluid}
          style={styles.desktop.background}
        />
        <Img
          fluid={data.head.childImageSharp.fluid}
          style={styles.desktop.head}
        />
        <Img
          fluid={data.arm.childImageSharp.fluid}
          style={styles.desktop.arm}
        />
        <Img
          fluid={data.grid.childImageSharp.fluid}
          style={styles.desktop.grid}
        />
        <Img
          fluid={data.circles.childImageSharp.fluid}
          style={styles.desktop.circles}
        />
      </Media>
    </>
  )
}

export default Background
