export const config = {
  numberOfTexts: 4,
  createTextInterval: 4,
}

export const syncAnimation = {
  rotate: [0, 15, 360],
  times: [0, 0.98, 1],
  transition: {
    loop: Infinity,
    ease: 'easeInOut',
    duration: config.createTextInterval * 1,
  },
}
