import React, { useReducer, useState } from 'react'
import styled from 'styled-components'
import colors from '../../lib/colors'
import SectionHeader from '../shared/SectionHeader'
import Carousel from 'nuka-carousel'
import testimonials from './testimonials'
import useWindowSize from '../../lib/hooks/useWindowSize'
import SymbolButton from '../shared/SymbolButton'
import Card from './Card'

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: ${colors.greylight};
  color: ${colors.white};
  padding: 50px 0px;
  overflow: hidden;
`

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  @media (max-width: 1280px) {
    flex-direction: column;
    justify-content: center;
    width: 90%;
  }
`

const Arrows = styled.div`
  display: flex;
  width: 120px;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
`

const rightArrow = <span className="lnr lnr-arrow-right"></span>
const leftArrow = <span className="lnr lnr-arrow-left"></span>

const controlStyles = { height: '50px', width: '50px' }
const defaultCarouselProps = {
  cellAlign: 'center',
  slideWidth: '500px',
  cellSpacing: 20,
  framePadding: '0px 0px 75px 0px',
  frameOverflow: 'visible',
  renderCenterRightControls: null,
  renderCenterLeftControls: null,
  renderBottomCenterControls: (props) => {
    return (
      <Arrows>
        <SymbolButton
          symbol={leftArrow}
          onClick={props.previousSlide}
          styles={controlStyles}
        />
        <SymbolButton
          symbol={rightArrow}
          onClick={props.nextSlide}
          styles={controlStyles}
        />
      </Arrows>
    )
  },
}

const getPropsForWidth = (width) => {
  if (width > 1700) return { slidesToShow: 6 }
  else if (width > 1400) return { slidesToShow: 5 }
  else if (width > 1100) return { slidesToShow: 4 }
  else if (width > 767) return { slidesToShow: 2 }
  return { slidesToShow: 1, slideWidth: 1 }
}

const INITIAL_SLIDE_INDEX = Math.floor(testimonials.length / 2)

const Testimonials = React.memo(() => {
  const [slideIndex, setSlideIndex] = useState(INITIAL_SLIDE_INDEX)

  const size = useWindowSize()
  const customPropsForWidth = getPropsForWidth(size.width)
  const carouselProps = { ...defaultCarouselProps, ...customPropsForWidth }

  const [forceUpdateCounter, forceUpdate] = useReducer((x) => x + 1, 0)
  const testimonialCards = testimonials.map((testimonial) => (
    <Card
      key={testimonial.name}
      testimonial={testimonial}
      forceSectionUpdate={forceUpdate}
    />
  ))

  return (
    <Section id="testimonials">
      <SectionContent>
        <SectionHeader
          topTitle="What Our Clients Are Saying"
          bottomTitle="Testimonials"
        />
        <Carousel
          {...carouselProps}
          x={forceUpdateCounter}
          slideIndex={slideIndex}
          afterSlide={(slideIndex) => setSlideIndex(slideIndex)}
        >
          {testimonialCards}
        </Carousel>
      </SectionContent>
    </Section>
  )
})

export default Testimonials
