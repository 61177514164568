import React from 'react'
import './styles.css'
import styled from 'styled-components'
import colors from '../../../lib/colors'
import {
  IoIosPerson,
  IoIosCall,
  IoIosAt,
  IoMdMailOpen,
  IoIosBusiness,
} from 'react-icons/io'

const SubmitButton = styled.input`
  margin-top: 10px;
  color: ${colors.white};
  width: 234px;
  height: 60px;
  box-shadow: 0 10px 20px rgba(53, 67, 94, 0.5);
  border-radius: 5px;
  background-color: ${colors.red};
  font-size: 15px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const SubmittedForm = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class EmailForm extends React.Component {
  state = {
    name: '',
    phone: '',
    email: '',
    company: '',
    message: '',
    submitted: false,
  }

  handleInputChange = (event) => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => this.setState({ submitted: true }))
      .catch((error) => alert(error))
  }

  render() {
    if (this.state.submitted)
      return (
        <SubmittedForm>
          Thanks for contacting us, we will be in touch shortly.
        </SubmittedForm>
      )
    return (
      <form
        onSubmit={this.handleSubmit}
        className="form"
        id="contact"
        data-netlify="true"
        name="Contact"
      >
        <div className="form-inputs">
          <div className="form-input">
            <label>
              <div className="input-wrapper">
                <IoIosPerson color="white" size={35} />
                <input
                  type="text"
                  autoComplete="off"
                  name="name"
                  value={this.state.name}
                  onChange={this.handleInputChange}
                  placeholder="Your Name"
                  required
                />
              </div>
            </label>
          </div>
          <div className="form-input">
            <label>
              <div className="input-wrapper">
                <IoIosCall color="white" size={35} />
                <input
                  type="text"
                  autoComplete="off"
                  name="phone"
                  value={this.state.phone}
                  onChange={this.handleInputChange}
                  placeholder="Your Phone"
                />
              </div>
            </label>
          </div>
          <div className="form-input">
            <label>
              <div className="input-wrapper">
                <IoIosAt color="white" size={35} />
                <input
                  type="text"
                  autoComplete="off"
                  name="email"
                  value={this.state.email}
                  onChange={this.handleInputChange}
                  placeholder="Your E-Mail"
                  required
                />
              </div>
            </label>
          </div>
          <div className="form-input">
            <label>
              <div className="input-wrapper">
                <IoIosBusiness color="white" size={35} />
                <input
                  type="text"
                  autoComplete="off"
                  name="company"
                  value={this.state.company}
                  onChange={this.handleInputChange}
                  placeholder="Company Name"
                />
              </div>
            </label>
          </div>
          <div className="form-input message">
            <label>
              <div className="input-wrapper">
                <IoMdMailOpen color="white" size={35} />
                <textarea
                  name="message"
                  autoComplete="off"
                  value={this.state.message}
                  onChange={this.handleInputChange}
                  placeholder="Your Message"
                  required
                ></textarea>
              </div>
            </label>
          </div>
        </div>
        <div className="form-button">
          <SubmitButton
            type="submit"
            className="animated button"
            value="Get in Touch"
          />
        </div>
      </form>
    )
  }
}
