import React from 'react'
import styled, { css } from 'styled-components'
import colors from '../../../lib/colors'

const Button = styled.div`
  margin: 10px;
  width: 125px;
  height: 50px;
  box-shadow: 0 5px 10px rgba(53, 67, 94, 0.5);
  border: 1px solid rgb(74, 79, 88);
  border-radius: 10px;
  background-color: transparent;
  font-size: 15px;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 768px) {
    font-size: 12px;
    text-align: center;
    flex-basis: 40%;
  }
  ${props =>
    props.active &&
    css`
      color: ${colors.white};
      background-color: ${colors.red};
      border: none;
    `};
`

class CategoryButton extends React.Component {
  render() {
    const { isActive, text, click } = this.props
    return (
      <Button active={isActive} onClick={click} className="animated button">
        {text}
      </Button>
    )
  }
}

export default CategoryButton
